@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or App.css */
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@keyframes scrollLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .icon-marquee {
    display: flex;
    gap: 2rem;
    animation: scrollLeftToRight 15s linear infinite;
  }
  
  .icon-marquee:hover {
    animation-play-state: paused;
  }
  
  /* Ensuring the parent div does not show overflow */
  .w-full {
    overflow: hidden;
  }
  